@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body,
#root,
.app {
  font-size: 12;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loading-image {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: url("/public/assets/loadImg.jpg") center/cover no-repeat; */
}

.footerContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 2rem;
  align-items: center;
}

.footerContainer > a {
  text-decoration: none;
}

.footerLinks,
.policy {
  color: white;
}

.footerLinks:hover,
.policy:hover {
  text-decoration: underline;
}

.projectContainer > a {
  text-decoration: none;
}

.underline:hover {
  text-decoration: underline;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.navbarButton > span {
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  pointer-events: none;
  animation: ripples 0.4s linear infinite;
}
@keyframes ripples {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: 450px;
    height: 450px;
    opacity: 0;
  }
}

#active::before {
  color: #ce0000;
  transform: scaleX(0.7);
}

#active {
  color: #ce0000;
}

.coverImage {
  display: block;
  width: 100%;
  max-width: 100%;
}
